/**
 * 
 */

import 'zoom-vanilla.js/dist/zoom.css'
import 'zoom-vanilla.js/dist/zoom-vanilla.min.js'

/**
 * MediumZoom.
 */

export class MediumZoom {

    /**
     * constructor.
     */

    constructor() {

    }

    /**
     * start.
     */

    start(): void {

        /**
         * All we're doing here is importing the zoom-vanilla files (above).
         */

    }

}
